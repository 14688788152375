var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"header\">\n    <div class=\"dialog--header\">\n        <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"you_already_have_products_in_your_checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":93}}}))
    + "</h2>\n        <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n    </div>\n</div>\n\n<div class=\"dialog--content\">\n    <div data-ui=\"message\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"text_here",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":59}}}))
    + "</div>\n</div>\n\n<div class=\"dialog--footer\">\n    <div class=\"buttons\">\n        <button class=\"btn btn-warning btn-regular\" data-action=\"add-products\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_products",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":79},"end":{"line":14,"column":114}}}))
    + "</button>\n        <button class=\"btn btn-success btn-regular\" data-action=\"clean-checkout\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"clean_checkout_and_add_products",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":81},"end":{"line":15,"column":135}}}))
    + "</button>\n        <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":72},"end":{"line":16,"column":101}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});