var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\n<table class=\"table\">\n    <thead>\n    <tr>\n        <th>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":42}}}))
    + "\n        </th>\n        <th>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":39}}}))
    + "\n        </th>\n        <th>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":40}}}))
    + "\n        </th>\n        <th>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"action",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":41}}}))
    + "\n        </th>\n    </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n";
},"useData":true});