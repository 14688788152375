var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"form-group\">\n            <div data-ui=\"serial-container\">\n                <label class=\"control-label\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"serial_numbers",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":82}}}))
    + "</label>\n                <div data-region=\"serials-input\"></div>\n            </div>\n            <div class=\"help-block\"></div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"buy_used_goods",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":61}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"input\">\n        <div class=\"form-group\">\n            <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":76}}}))
    + "</label>\n            <input type=\"text\" name=\"name\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":77},"end":{"line":9,"column":112}}}))
    + "\" autofocus value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":131},"end":{"line":9,"column":139}}}) : helper)))
    + "\">\n            <div class=\"help-block\"></div>\n        </div>\n        <div class=\"form-group\">\n            <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":75}}}))
    + "</label>\n            <textarea name=\"description\" id=\"description\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":92},"end":{"line":14,"column":126}}}))
    + "\" data-ui=\"description\"></textarea>\n            <div class=\"help-block\"></div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSerialsEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"form-group\">\n            <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"price_incl_vat",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":41},"end":{"line":27,"column":78}}}))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"taxRateLabel") || (depth0 != null ? lookupProperty(depth0,"taxRateLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"taxRateLabel","hash":{},"data":data,"loc":{"start":{"line":27,"column":80},"end":{"line":27,"column":96}}}) : helper)))
    + ")</label>\n            <div data-region=\"ppu-input\"></div>\n            <div class=\"help-block\"></div>\n        </div>\n    </div>\n    <button class=\"btn btn-success accept\" data-action=\"confirm\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_to_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":43}}}))
    + "\n    </button>\n    <div data-region=\"keypad\" class=\"keypad\"></div>\n</div>\n";
},"useData":true});