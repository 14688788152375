var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isComplete") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":20,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h2 class=\"confirmation\" >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "                </h2>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"it_will_be_picked_up_in_store_at_{shop_full_address}_on_{time_requested}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":121}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"it_should_be_delivered_by_{time_requested}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":91}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td class=\"item-quantity\"><b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"quantity") || (depth0 != null ? lookupProperty(depth0,"quantity") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quantity","hash":{},"data":data,"loc":{"start":{"line":26,"column":53},"end":{"line":26,"column":65}}}) : helper)))
    + "&nbsp;&times;</b></td>\n                        <td class=\"item-category\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":62}}}) : helper)))
    + "</td>\n                        <td class=\"item-description\"><span class=\"item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":77},"end":{"line":28,"column":85}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sub_items") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":32,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instructions") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":35,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":30,"column":58}}})) != null ? stack1 : "")
    + "\n                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":32},"end":{"line":31,"column":40}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":69}}})) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<br/>";
},"11":function(container,depth0,helpers,partials,data) {
    return ",";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <br/><b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"instructions") || (depth0 != null ? lookupProperty(depth0,"instructions") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instructions","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":56}}}) : helper)))
    + "</b>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-warning pull-right\"> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":66},"end":{"line":45,"column":95}}}))
    + "</span><br/>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-info pull-right\"> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":94}}}))
    + "</span><br/>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":62,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":65,"column":23}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addressLine1") || (depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addressLine1","hash":{},"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":61,"column":36}}}) : helper)))
    + "<br/>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addressLine2") || (depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addressLine2","hash":{},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":36}}}) : helper)))
    + "<br/>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h4> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"customer_note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":70,"column":21},"end":{"line":70,"column":57}}}))
    + "</h4>\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"notes") || (depth0 != null ? lookupProperty(depth0,"notes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"notes","hash":{},"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":25}}}) : helper)))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                        <td class=\"text-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":80,"column":49},"end":{"line":80,"column":76}}}))
    + "</td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                        <td class=\"text-danger\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"to_be_paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":82,"column":48},"end":{"line":82,"column":81}}}))
    + "</td>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"row\">\n            <label for=\"time\" class=\"time control-label vertical-align col-md-4\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"confirmed_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":102,"column":81},"end":{"line":102,"column":118}}}))
    + "</label>\n            <div class=\"col-md-4\">\n                <input type=\"text\" class=\"ignore-keyboard form-control\" id=\"time\" name=\"time\">\n                <div class=\"help-block\"></div>\n            </div>\n        </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"button-container button-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"btnCount") || (depth0 != null ? lookupProperty(depth0,"btnCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"btnCount","hash":{},"data":data,"loc":{"start":{"line":110,"column":44},"end":{"line":110,"column":56}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":113,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasKitchenOnConfirm") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":12},"end":{"line":116,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasKitchen") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":119,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFinish") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":12},"end":{"line":131,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPayBtn") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":12},"end":{"line":134,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCancel") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":12},"end":{"line":137,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"confirm\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"confirm_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":112,"column":77},"end":{"line":112,"column":112}}}))
    + "</button>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"kitchen-with-confirm\" class=\"btn btn-lg btn-default\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"in_kitchen_print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":115,"column":90},"end":{"line":115,"column":129}}}))
    + "</button>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"kitchen\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"in_kitchen_print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":118,"column":77},"end":{"line":118,"column":116}}}))
    + "</button>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button data-action=\"reprint\" class=\"btn btn-lg btn-default\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"reprint_kitchen_delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":121,"column":77},"end":{"line":121,"column":124}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":130,"column":23}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":123,"column":20},"end":{"line":127,"column":27}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "                        <button data-action=\"mark-picked-up\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"mark_as_picked_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":124,"column":92},"end":{"line":124,"column":132}}}))
    + "</button>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "                        <button data-action=\"pay-order\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":126,"column":87},"end":{"line":126,"column":119}}}))
    + "</button>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "                    <button data-action=\"out-for-delivery\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"delivery_notify_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":129,"column":90},"end":{"line":129,"column":137}}}))
    + "</button>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"pay-order\" class=\"btn btn-lg btn-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":133,"column":79},"end":{"line":133,"column":111}}}))
    + "</button>\n";
},"49":function(container,depth0,helpers,partials,data) {
    return "                <button data-action=\"cancel\" class=\"btn btn-lg btn-danger\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"cancel_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":136,"column":75},"end":{"line":136,"column":110}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2>\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"order_{number}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "\n        -\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":22}}}) : helper)))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":38}}}) : helper)))
    + ")\n    </h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"col-md-8\">\n            <table class=\"table table-condensed item-table\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":39,"column":25}}})) != null ? stack1 : "")
    + "            </table>\n        </div>\n        <div class=\"col-md-4 details\">\n            <h4> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"order_details",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":17},"end":{"line":43,"column":53}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":48,"column":23}}})) != null ? stack1 : "")
    + "            </h4>\n\n            <table class=\"property-table\">\n                <tr><td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":53}}}))
    + "</td><td class=\"text-info\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":52,"column":80},"end":{"line":52,"column":94}}}) : helper)))
    + "</td></tr>\n            </table>\n\n            <h4> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"customer_info",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":55,"column":17},"end":{"line":55,"column":53}}}))
    + "</h4>\n            <b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"customerName") || (depth0 != null ? lookupProperty(depth0,"customerName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"customerName","hash":{},"data":data,"loc":{"start":{"line":56,"column":15},"end":{"line":56,"column":31}}}) : helper)))
    + "</b><br/>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":66,"column":23}}})) != null ? stack1 : "")
    + "            "
    + alias2(((helper = (helper = lookupProperty(helpers,"phoneNumber") || (depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"phoneNumber","hash":{},"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":67,"column":27}}}) : helper)))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notes") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":72,"column":19}}})) != null ? stack1 : "")
    + "\n            <h4> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":74,"column":17},"end":{"line":74,"column":47}}}))
    + "</h4>\n\n            <table class=\"property-table\">\n                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":54}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":83,"column":27}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"payment_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":86,"column":24},"end":{"line":86,"column":61}}}))
    + "</td>\n                    <td class=\"text-info\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentMethodName") || (depth0 != null ? lookupProperty(depth0,"paymentMethodName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"paymentMethodName","hash":{},"data":data,"loc":{"start":{"line":87,"column":42},"end":{"line":87,"column":63}}}) : helper)))
    + "</td>\n                </tr>\n                <tr>\n                    <td colspan=\"2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"paymentMethodDetails") || (depth0 != null ? lookupProperty(depth0,"paymentMethodDetails") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"paymentMethodDetails","hash":{},"data":data,"loc":{"start":{"line":90,"column":36},"end":{"line":90,"column":60}}}) : helper)))
    + "</td>\n                </tr>\n            </table>\n\n        </div>\n\n    </div>\n</div>\n<div class=\"dialog--footer\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasConfirmation") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":4},"end":{"line":108,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"btnCount") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":4},"end":{"line":139,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});