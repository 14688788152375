var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"for_{0}",((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":97}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/calendarTime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"common$calendarTime","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":30}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":104}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"text-right\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"item_no") || (depth0 != null ? lookupProperty(depth0,"item_no") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"item_no","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":36}}}) : helper)))
    + "</td>\n<td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":72}}}))
    + "</td>\n<td class=\"text-center actions\">\n    <button class=\"btn btn-default btn-regular\" data-action=\"load\">\n        <i class=\"far fa-shopping-cart\"></i>\n    </button>\n    <div data-region=\"popup\" class=\"hidden\"></div>\n</td>\n<td class=\"text-center actions\">\n    <button class=\"btn btn-default btn-regular\" data-action=\"delete\">\n        <i class=\"fas fa-trash\"></i>\n    </button>\n</td>\n";
},"useData":true});