var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"panel panel-info\">\n        <div class=\"panel-heading\">\n            <p class=\"panel-title\">\n                <i class=\"fas fa-envelope\"></i> <strong>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"created_by") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</strong> | <span>"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":124}}}))
    + "</span> | <span>"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias2,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":5,"column":140},"end":{"line":5,"column":180}}}))
    + "</span>\n            </p>\n        </div>\n        <div class=\"panel-body\">\n            <div class=\"row\">\n                <div class=\"col-md-12\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"panel panel-warning no-print\">\n        <div class=\"panel-heading\">\n            <p class=\"panel-title\">\n                <i class=\"fas fa-sticky-note\"></i> <strong>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"created_by") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</strong> | "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"extra") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":20,"column":92},"end":{"line":20,"column":208}}})) != null ? stack1 : "")
    + " | <span>"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias2,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":20,"column":217},"end":{"line":20,"column":257}}}))
    + "</span>\n            </p>\n        </div>\n        <div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formatted_extra") || (depth0 != null ? lookupProperty(depth0,"formatted_extra") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formatted_extra","hash":{},"data":data,"loc":{"start":{"line":20,"column":112},"end":{"line":20,"column":133}}}) : helper)))
    + "</span> ";
},"6":function(container,depth0,helpers,partials,data) {
    return " <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"internal_note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":156},"end":{"line":20,"column":193}}}))
    + "</span> ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"old_status") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                    ("
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_remarks",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":21},"end":{"line":28,"column":56}}}))
    + ")\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"repair_created_by_{name}",(depth0 != null ? lookupProperty(depth0,"created_by") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":80}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_email_type") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"useData":true});