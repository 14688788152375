var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td class=\"text-left\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_paid") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":51}}}))
    + "\n            </td>\n            <td class=\"text-center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_paid") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </td>\n            <td class=\"text-left\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":25}}}) : helper)))
    + "\n                <br>\n                <small>"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":26,"column":23},"end":{"line":26,"column":38}}}) : helper)))
    + "</small>\n            </td>\n            <td class=\"text-right\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":62}}}))
    + "\n            </td>\n        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fas text-success fa-2x fa-check\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fas text-danger fa-2x fa-times\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-vcenter table-striped\">\n    <thead>\n    <tr>\n        <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date_paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":44}}}))
    + "</th>\n        <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"paid",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":39}}}))
    + "</th>\n        <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":46}}}))
    + "</th>\n        <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":41}}}))
    + "</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"payments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":32,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});