var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"new_product_syncing_done",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":65}}}))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"newProductSyncTotal") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"inserting_{new_product_sync_total}_products",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":22},"end":{"line":11,"column":90}}}))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"initializing",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":22},"end":{"line":13,"column":57}}}))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "progress-bar-striped active";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"newProductSyncTotal") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":27}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"skipped_dot_all_products_are_synced",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":84}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"syncNewPercentage") || (depth0 != null ? lookupProperty(depth0,"syncNewPercentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"syncNewPercentage","hash":{},"data":data,"loc":{"start":{"line":27,"column":22},"end":{"line":27,"column":45}}}) : helper)))
    + "%</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "            <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"updated_products_syncing_done",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":18},"end":{"line":34,"column":70}}}))
    + "</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"updateProductSyncTotal") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"updating_{update_product_sync_total}_existing_products",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":22},"end":{"line":37,"column":101}}}))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"updateProductSyncTotal") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":51,"column":27}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"skipped_dot_all_products_are_updated",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":26},"end":{"line":50,"column":85}}}))
    + "</span>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"syncUpdatePercentage") || (depth0 != null ? lookupProperty(depth0,"syncUpdatePercentage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"syncUpdatePercentage","hash":{},"data":data,"loc":{"start":{"line":53,"column":22},"end":{"line":53,"column":48}}}) : helper)))
    + "%</span>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "            <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"waiting_for_first_task_to_finish",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":18},"end":{"line":57,"column":74}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":35}}}) : helper)))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncNewDone") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"progress\">\n            <div data-ui=\"progressbar-new\"\n                 class=\"progress-bar progress-bar-success "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncNewDone") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":58},"end":{"line":18,"column":119}}})) != null ? stack1 : "")
    + "\"\n                 role=\"progressbar\"\n                 style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"syncNewPercentage") || (depth0 != null ? lookupProperty(depth0,"syncNewPercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"syncNewPercentage","hash":{},"data":data,"loc":{"start":{"line":20,"column":31},"end":{"line":20,"column":54}}}) : helper)))
    + "%\">\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncNewDone") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <hr>\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncUpdateDone") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"progress\">\n            <div data-ui=\"progressbar-update\"\n                 class=\"progress-bar progress-bar-success "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncUpdateDone") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":58},"end":{"line":44,"column":122}}})) != null ? stack1 : "")
    + "\"\n                 role=\"progressbar\"\n                 style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"syncUpdatePercentage") || (depth0 != null ? lookupProperty(depth0,"syncUpdatePercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"syncUpdatePercentage","hash":{},"data":data,"loc":{"start":{"line":46,"column":31},"end":{"line":46,"column":57}}}) : helper)))
    + "%\">\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncUpdateDone") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncNewDone") : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":58,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"dialog--footer\">\n    <div class=\"one-button-container\">\n        <button data-ui=\"stop-sync\" class=\"btn btn-regular btn-danger\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"stop_and_continue_on_pos",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":63,"column":71},"end":{"line":63,"column":118}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});