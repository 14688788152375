var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"open_pin_transaction",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":67}}}))
    + "</i></h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <h3 class=\"h3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"there_is_an_open_pin_transaction_that_needs_to_be_recovered",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":101}}}))
    + "</h3>\n\n    <p class=\"h5\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"there_is_still_an_open_pin_transaction_that_needs_to_be_recovered_dot_until_it_is_recovered_comma_no_new_pin_transaction_can_take_place_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":180}}}))
    + "</p>\n    <p class=\"h5\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"to_recover_the_transaction_comma_make_sure_the_pin_device_is_connected_correctly_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":125}}}))
    + "</p>\n\n    <div class=\"transaction-info\">\n        <div class=\"row\">\n            <div class=\"col-xs-4\">\n                <label class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total_amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":76}}}))
    + "</label>\n            </div>\n            <div class=\"col-xs-6\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"totalAmount") || (depth0 != null ? lookupProperty(depth0,"totalAmount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalAmount","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":31}}}) : helper)))
    + "\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-xs-4\">\n                <label class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date_started",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":41},"end":{"line":22,"column":76}}}))
    + "</label>\n            </div>\n            <div class=\"col-xs-6\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"dateStarted") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":53}}}))
    + "\n            </div>\n        </div>\n    </div>\n\n    <br>\n\n    <button class=\"btn btn-info btn-regular\" data-action=\"recover\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"recover_pin_transaction",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":67},"end":{"line":32,"column":113}}}))
    + "</button>\n</div>\n";
},"useData":true});